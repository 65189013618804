:root {
  --background: #000000;
  --secondary: #8a2be2;

  --bg-color: #260155;
  --button-color: #159192;
  --input-field: #3b1072;
  --heading-color: #ffffff;
  --subtext-color: #a9a3ba;
  --purple-shades: mediumslateblue;
  --picker-1: #260b64;
  --picker-2: #233177;
  --picker-3: #3e0f71;
}

body {
  background-color: #000000;
  color: white;
}
#div-tint {
  /* background: linear-gradient(
      217deg,
      var(--bg-color),
      rgba(255, 0, 0, 0.019) 70.71%
    ),
    linear-gradient(127deg, var(--bg-color), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(127deg, var(--button-color), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(300deg, var(--bg-color), rgba(0, 255, 0, 0) 70.71%); */
  background: linear-gradient(
    to top right,
    var(--bg-color),
    var(--bg-color),
    var(--button-color)
  );

  opacity: 0.89;
}

#nav-bar {
  margin: auto;
  opacity: 0.9;
}
a {
  color: #ffffff;
  text-decoration: none;
}
#Home {
  padding-top: 100px;
  padding-bottom: 100px;
}

#page-1 {
  background-image: url("/src/Assets/page2.jpg");
  background-attachment: fixed;
  background-size: cover;
}
#front-page {
  font-size: 50px;
  font-family: "Bebas Neue", sans-serif;
  margin-top: 0px;
  color: white;
}
#frontpage-img-div {
  padding-bottom: 50px;

  opacity: 0.9;
}
h1 {
  text-align: left;
}
p {
  text-align: center;
  margin-bottom: 20px;
}
#resume {
  padding: 5px;
  font-size: 20px;
  /* font-family:fantasy; */
  font-weight: bolder;
  text-decoration: underline;
  color:#159192;
}
#resume-footer {
  
  font-size: 20px;
  /* font-family:fantasy; */
  font-weight: bolder;
  text-decoration: underline;
  color:#159192;
}
#resume:hover{
  color:#ffffff;
}
#resume-footer:hover{
  color:#ffffff;
}

#about-me-div {
  padding: 100px;
  /* background-image: url(/src/Assets/gradient.jpeg); 
  background-attachment: fixed;
  background-size: cover;*/
  opacity: 0.9;
  /* background-image:linear-gradient(to bottom right,var(--bg-color),var(--bg-color),var(--button-color)); */
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  background: linear-gradient(
      217deg,
      var(--bg-color),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, var(--bg-color), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(127deg, var(--button-color), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(127deg, var(--button-color), rgba(0, 255, 0, 0) 70.71%);
}

#aboutme-descriptive {
  margin: auto;
  border-radius: 10px;
  border: 1px solid var(--bg-color);
  background-color: rgba(179, 226, 245, 0.1); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  text-align: left;
  color: white;
  overflow: hidden;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);

}
/* #my-image {
 
  height: 450px;
} */

#hire-me {
  font-size: 30px;
  width: 300px;
  height: 90px;
  border-radius: 80px;
  border: #ffffff;
  color: #ffffff;
  margin-top: 25px;
  background: linear-gradient(to left, #159192 50%, white 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
}
#hire-me:hover {
  background-position: left;
  color: var(--bg-color);
  font-style: italic;
  font-weight: bolder;
  font-size: 40px;
  height: 100px;
}
li {
  list-style-type: none;
}
ul {
  margin: 0px;
}
li {
  margin: 10px;
}
.name {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: aliceblue;
}
#Projects {
  background-image: url(/src/Assets/page1.jpg);
  background-attachment: fixed;
  background-size: cover;
}

.project-box1 {
  display: inline-block;
  background-image: url("/src/Assets/guruwebapp.png");
  width: 400px;
  height: 400px;
  border-radius: 50px;
  background-size: cover;
  position: relative;
  margin: 10px;
  opacity:0.7;
  /* border-top: 2px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
   */
 
}
.project-box1:hover{
  height:450px;
  border-radius:10px;
  opacity:10;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
 
}
.project-box2:hover{
  height:450px;
  border-radius: 10px;
  opacity:10;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
}
.project-box3:hover{
  height:450px;
  border-radius: 10px;
  opacity:10;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
}
.project-box4:hover{
  height:450px;
  border-radius: 10px;
  opacity:10;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
}
.project-box5:hover{
  height:450px;
  border-radius: 10px;
  opacity:10;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
}
.project-box6:hover{
  height:450px;
  border-radius: 10px;
  opacity:10;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
}

.project-box2 {
  display: inline-block;
  background-image: url("/src/Assets/booksApp.png");
  width: 400px;
  height: 400px;
  border-radius: 50px;
  background-size: cover;
  position: relative;
  margin: 10px;
  opacity:0.7;
}
.project-box3 {
  display: inline-block;
  background-image: url("/src/Assets/notestaker.png");
  width: 400px;
  height: 400px;
  border-radius: 50px;
  background-size: cover;
  position: relative;
  margin: 10px;
  opacity:0.7;
}
.project-box4 {
  display: inline-block;
  background-image: url("/src/Assets/calendar.png");
  width: 00px;
  width: 400px;
  height: 400px;
  border-radius: 50px;
  background-size: cover;
  position: relative;
  margin: 10px;
  opacity:0.7;
}
.project-box5 {
  display: inline-block;
  background-image: url("/src/Assets/weather.png");
  width: 400px;
  height: 400px;
  border-radius: 50px;
  background-size: cover;
  position: relative;
  margin: 10px;
  opacity:0.7;
}
.project-box6 {
  display: inline-block;
  background-image: url("/src/Assets/Quiz.png");
  width: 400px;
  height: 400px;
  border-radius: 50px;
  background-size: cover;
  position: relative;
  margin: 10px;
  opacity:0.7;
}
#projects-heading {
  font-size: 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.project-info {
  
  border: 1px solid var(--background);
  border-radius: 8px;
  height: 90px;
  position: absolute;
  background-color: #000000;
  opacity: 0.9;
  bottom: 0;
  width: 400px;
  color: white;
}
.project-header{
  font-size: 30px;;

}

.weblink-btn {
  float: right;
  padding: 50px;
  margin-left: 100px;
}
.btn-weblink {
  background-color: var(--button-color);
  width: 150px;
  height: 40px;
  border-radius: 40px;
  color: white;
  font-size: 15px;
  font-weight: bolder;
}

#form-div {
  background-image: linear-gradient(
    to bottom left,
    var(--picker-2),
    var(--picker-1),
    var(--picker-1),
    var(--picker-1),
    var(--picker-2)
  );
  opacity: 0.8;
}
#Contacts {
  background-image: url(/src/Assets/page3.jpg);
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.8;
}
form {
  margin-top: 40px;
  text-align: center;
}
input {
  margin: 20px;
  text-align: center;
  background-color: var(--secondary);
  color: white;
  border: 3px solid grey;
}
input::placeholder {
  font-weight: bold;
  opacity: 0.9;
  color: white;
  font-family: fantasy;
}
#form-name {
  font-weight: bolder;
  font-size: larger;
  width: 300px;
  height: 50px;
  border-radius: 5px;
  color:#000000;
}
#form-Email {
  font-weight: bolder;
  font-size: larger;
  color:#000000;
  width: 300px;
  height: 50px;
  border-radius: 5px;
}
/* #form-number {
  font-weight: bolder;
  font-size: larger;
  color:#000000;
  width: 300px;
  height: 50px;
  border-radius: 5px;
} */
#form-subject {
  font-weight: bolder;
  font-size: larger;
  color:#000000;
  width: 650px;
  height: 50px;
  border-radius: 5px;
}
#form-comment {
  font-weight: bolder;
  font-size: larger;
  color:#000000;
  width: 650px;
  height: 250px;
  border-radius: 5px;
  font-size: medium;
}
#form-submit {
  border-radius: 3px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #159192;
  width: 200px;
  height: 50px;
  border-radius: 50px;
  color: #ffffff;
  font-size: medium;
  border: 1px solid grey;
}
#footer {
  /* background-image: linear-gradient(
    to bottom left,
    var(--background),
    var(--bg-color)
  ); */
  padding: 10px;
  background-image: linear-gradient(var(--picker-1), var(--bg-color));
  opacity: 0.75;
  border: 1px solid var(--picker-2);
}
/* ============================================================================================!!!!!! */
@media only screen and (min-width: 1000px) {
  
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
  }
 
  #resume-li{
    margin-top: 20px;
    color:#159192;
    text-decoration: underline;
  }
  #contact-heading {
    font-size: 60px;
    padding: 30px;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  }
  #contact-text {
    font-size: 25px;
    color: #a9a3ba;
  }
  #project-container {
    overflow: auto;
    white-space: nowrap;
    opacity: 0.9;
    background: linear-gradient(
        217deg,
        var(--background),
        rgba(12, 11, 11, 0) 10%
      ),
      linear-gradient(
        150deg,
        var(--button-color),
        rgba(55, 229, 220, 0.01) 20.71%
      ),
      linear-gradient(-10deg, var(--button-color), rgba(0, 255, 0, 0) 10.71%);
    padding-top: 100px;
    padding-left: 150px;
    padding-bottom: 150px;
  }

  #aboutme-heading {
    text-align: center;
    font-size: 35px;
  }

  #aboutme-descriptive {
    display: flex;
    flex-wrap: wrap;
  }
  .p-1 {
    text-align: left;
    /* font-family: fantasy */
    font-family:'Times New Roman', Times, serif;
  }
  #heartfulness{
font-family: fantasy;

  }
  #heartfulness:hover{
    text-decoration: underline;

  }
  #aboutme-info {
    font-size: 20px;
    padding: 100px 80px 100px 80px;
  }
  #front-page {
    margin-top: 0px;
  }
  .name {
    font-size: 30px;
    margin-left:380px;
  }
  #aboutme-img {
    padding: 10px;
    margin-top: 70px;
    margin-right: 10px;
  }
  #my-image {

    --color: var(--heading-color); /* the border color */
    --border: 1px; /* the border thickness*/
    --offset: 20px; /* control the offset*/
    --gap: 5px; /* the gap on hover */

    --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)),
      var(--color) 0;
    --_o: calc(3 * var(--offset));
    padding: calc(var(--gap) + var(--border))
      calc(var(--gap) + var(--border) + var(--offset))
      calc(var(--gap) + var(--border) + var(--offset))
      calc(var(--gap) + var(--border));
    background:
      linear-gradient(var(--_c)) var(--_o) var(--_o),
      linear-gradient(90deg, var(--_c)) var(--_o) var(--_o);
    background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
    background-repeat: no-repeat;
    filter: grayscale(0);
    transition: 0.5s;
    cursor: pointer;
   height: 400px;
  }

  #my-image:hover {
    --border: 5px;


    background-position: 0px 0px;
    background-size: calc(100% - var(--offset)) calc(100% - var(--offset));
    filter: grayscale(0);
  }
  #frontpage-image {
    height: 350px;
    animation: 2s ease-out 0s 1 slideInFromLeft;
    border-radius: 50%;
    border: 10px solid var(--bg-color);
    margin-bottom: 0px;
    /* border-image:   linear-gradient(to right, grey 25%, yellow 25%, yellow 50%,red 50%, red 75%, teal 75%) 5; */
  }
  #home-link {
    margin-left: 210px;
    margin-top: 20px;
  }
  #work-link {
    margin-right: 20px;
    margin-left: 25px;
    margin-top: 20px;
  }
  #about-link {
    margin-right: 25px;
    margin-top: 20px;
  }
  #getintouch-link {
    margin-right: 20px;
    margin-top: 20px;
  }
}
/* =================================================!!!!!!!!!!!+==================================== */
@media screen and (max-width: 600px) {
  ul {
    display: block;
  }
  #res-non{
    color:var(--button-color);
    text-decoration: underline;
  
    
  }
  li {
    font-size: 20px;
  }
  #frontpage-image {
    height: 330px;
    border-radius: 10px;
  }
  #resume{
    display:none;
  }
  #frontpage-p {
    font-size: 20px;
    text-align: center;
    color: #a9a3ba;
  }
  #nav-bar {
    margin: auto;
    background-color: #000000;
    opacity: 10;
  }
  #hire-me {
    width: 250px;
    height: 50px;
    border-radius: 80px;
    border: #ffffff;
    color: #ffffff;
    margin-top: 20px;
  }

  #aboutme-img {
   padding: 10px;
    margin-left: 5px;
  }
  #my-image {
    width: 300px;
    border-radius: 10%;
  }
  .name {
    font-size: 20px;
  }
  #front-page {
    font-size: 60px;
    text-align: center;
    margin: 0px;
  }
  #aboutme-descriptive {
    margin: auto;
    border-radius: 10px;
  }
  .project-info {
    display: none;
  }

  .project-box1 {
    display: inline-block;
    background-image: url("/src/Assets/guruwebapp.png");
    width: 300px;
    height: 400px;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    margin: 10px;
    border: 1px solid white;
  }

  .project-box2 {
    display: inline-block;
    background-image: url("/src/Assets/booksApp.png");
    width: 300px;
    height: 400px;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    margin: 10px;
  }
  .project-box3 {
    display: inline-block;
    background-image: url("/src/Assets/notestaker.png");
    width: 300px;
    height: 400px;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    margin: 10px;
  }
  .project-box4 {
    display: inline-block;
    background-image: url("/src/Assets/calendar.png");
    width: 300px;
    height: 400px;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    margin: 10px;
  }
  .project-box5 {
    display: inline-block;
    background-image: url("/src/Assets/wea2.png");
    width: 300px;
    height: 400px;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    margin: 10px;
  }
  .project-box6 {
    display: inline-block;
    background-image: url("/src/Assets/Quiz.png");
    width: 300px;
    height: 400px;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    margin: 10px;
  }

  #project-container {
    display: flex;
    flex-wrap: wrap;
  }

  input {
    margin-bottom: 5px;
    text-align: center;
  }
  #form-subject{
    width: 300px;
    height: 80px;
  }
  #form-comment {
    width: 300px;
    height: 100px;
    border-radius: 5px;
  }
  #about-me-div {
    padding: 10px;
    /* background-image: linear-gradient(var(--), var(--bg-color)); */

  }



  
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
    border: 10px solid black;
  }
}

